<template>
  <hub-modal qa-name="reprocess-modal" @input="$emit('input', $event)" :value="value" persistent>
    <hub-modal-header fill-content>
      <template #title>
        {{ $t('failedOrders.reprocessOrderModalTitle') }}
      </template>
    </hub-modal-header>

    <hub-modal-content>
      <div class="modal-content">
        {{ modalContent }}
      </div>
      <div class="modal-content">
        <ul>
          <i18n path="failedOrders.reprocessWithoutContent" tag="li">
            <template v-slot:reprocessWithout>
              <b>{{ $t('failedOrders.reprocessWithoutButton') }}</b>
            </template>
          </i18n>
          <i18n path="failedOrders.reprocessWithContent" tag="li">
            <template v-slot:reprocessWith>
              <b>{{ $t('failedOrders.reprocessWithButton') }}</b>
            </template>
          </i18n>
        </ul>
      </div>
      <div class="modal-content">
        {{ $t('failedOrders.reprocessOrdersExplanation') }}
      </div>
    </hub-modal-content>

    <template #actions>
      <hub-button @click="onCancel" variant="secondary" name="cancel-btn">
        {{ $t('common.cancel') }}
      </hub-button>
      <hub-button
        @click="onEmitFallbackOptions($event, false)"
        name="reprocess-without-btn"
        :loading="loadingWithout"
      >
        {{ $t('failedOrders.reprocessWithoutButton') }}
      </hub-button>
      <hub-button
        @click="onEmitFallbackOptions($event, true)"
        name="reprocess-with-btn"
        :loading="loadingWith"
      >
        {{ $t('failedOrders.reprocessWithButton') }}
      </hub-button>
    </template>
  </hub-modal>
</template>

<script>
import i18n from '@/boot/i18n'

export default {
  name: 'ReprocessOrdersModal',
  components: {},
  props: {
    value: Boolean,
    ordersTotal: { type: Number, Default: 0 },
    fallbackTotal: { type: Number, Default: 0 },
  },
  data: () => ({
    loadingWithout: false,
    loadingWith: false,
  }),
  computed: {
    modalContent() {
      return i18n.t('failedOrders.reprocessOrderModalContent', {
        totalNumber: this.ordersTotal,
        fallback: this.fallbackTotal,
      })
    },
  },
  methods: {
    onCancel() {
      this.$emit('close')
    },
    onEmitFallbackOptions(event, booleanValue) {
      booleanValue ? (this.loadingWith = true) : (this.loadingWithout = true)
      setTimeout(() => {
        this.$emit('reprocess', { event, fallback: booleanValue })
      }, 3000)
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-content {
  margin-bottom: 1rem;
}
</style>
