<template>
  <huboo-page>
    <huboo-page-heading :heading="$t('failedOrders.failedOrders') | title"></huboo-page-heading>
    <hub-notification-banner
      dismissable
      icon-name="fa-info-circle"
      qa-name="dismissable"
      variant="info"
      class="banner"
      v-if="toggleBanner"
    >
      {{ bannerMessage }}
    </hub-notification-banner>
    <huboo-table
      id="failed-orders"
      @update:options="handleUpdateOptions"
      hide-date-picker
      hide-search
      :headers="headers"
      :items="items"
      v-bind="options"
      :server-items-length="serverItemsLength"
      :loading="loadingStates"
    >
      <template #actions>
        <export-button
          v-if="exportUrl"
          :file-name="exportName"
          :export-url="exportUrl"
          :disabled="isButtonDisabled"
        />

        <v-btn @click="onImportCsvClicked" v-if="isAdmin" color="primary" :disabled="loading">
          {{ $t('common.importFromCsv') }}
        </v-btn>

        <v-btn
          :loading="loadingStates"
          :disabled="isButtonDisabled"
          color="primary"
          @click="onReprocessOpenModal"
          id="reprocessButton"
        >
          {{ $t('failedOrders.reprocessButton') }}
        </v-btn>
      </template>
      <template #filter>
        <huboo-filters
          :filters="filters"
          @filtersClear="onFiltersClear"
          @filtersApply="onApplyFilters"
          :activeFilters="activeFilters"
        >
          <v-row class="filter-row">
            <v-col cols="3" sm="6" md="4" class="date-picker">
              <huboo-date-range-picker @update="updateDateRange" modal-width="300px" />
            </v-col>
            <v-col cols="3" sm="6" md="4">
              <v-text-field
                dense
                outlined
                :label="$t('failedOrders.filters.hubooBox')"
                v-model="hubooBoxFilter"
              />
            </v-col>
            <div class="break"></div>

            <v-col cols="3" sm="6" md="4">
              <v-select
                :label="$t('failedOrders.filters.exceptionType')"
                :items="availableExceptionType"
                :item-value="availableExceptionType.value"
                :item-title="availableExceptionType.name"
                v-model="exceptionTypeFilter"
                dense
                outlined
              />
            </v-col>
            <v-col cols="3" sm="6" md="4">
              <v-select
                :label="$t('failedOrders.filters.status')"
                :items="availableStatus"
                :item-value="availableStatus.value"
                :item-title="availableStatus.name"
                v-model="statusFilter"
                dense
                outlined
              />
            </v-col>
          </v-row>
        </huboo-filters>
      </template>
    </huboo-table>

    <component
      :is="modal.component"
      v-model="modal.show"
      @close="modal.show = false"
      @reprocess="reprocessOrders"
      :orders-total="serverItemsLength"
      :fallback-total="fallbackNumber"
    />
  </huboo-page>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import i18n from '@/boot/i18n'
import { title } from '@/utilities/filters'
import ClientMixin from '@/mixins/client'

import FailedOrdersUploadModal from './FailedOrdersUploadModal.vue'
import ReprocessOrdersModal from './ReprocessOrdersModal.vue'
import ExportButton from '@/components/pages/ExportButton.vue'

const tableHeaders = [
  {
    text: title(i18n.t('failedOrders.tableHeaders.status')),
    sortable: false,
    value: 'status',
  },
  {
    text: title(i18n.t('failedOrders.tableHeaders.hubooBox')),
    sortable: false,
    value: 'hubooBox',
  },
  {
    text: title(i18n.t('failedOrders.tableHeaders.client.invoiceName')),
    sortable: false,
    value: 'client.invoiceName',
  },
  {
    text: title(i18n.t('failedOrders.tableHeaders.exceptionType')),
    sortable: false,
    value: 'exceptionType',
  },
  {
    text: title(i18n.t('failedOrders.tableHeaders.shippingServiceCode')),
    sortable: false,
    value: 'billingHeaderInput.shippingServiceCode',
  },
  {
    text: title(i18n.t('failedOrders.tableHeaders.originRegion')),
    sortable: false,
    value: 'billingHeaderInput.originRegion',
  },
  {
    text: title(i18n.t('failedOrders.tableHeaders.destinationRegion')),
    sortable: false,
    value: 'billingHeaderInput.destinationRegion',
  },
  {
    text: title(i18n.t('failedOrders.tableHeaders.destinationZone')),
    sortable: false,
    value: 'billingHeaderInput.destinationZone',
  },
  {
    text: title(i18n.t('failedOrders.tableHeaders.weight')),
    sortable: false,
    value: 'billingHeaderInput.weight',
  },
  {
    text: title(i18n.t('failedOrders.tableHeaders.currency')),
    sortable: false,
    value: 'billingHeaderInput.currency',
  },
  {
    text: title(i18n.t('failedOrders.tableHeaders.containerTypeCode')),
    sortable: false,
    value: 'billingHeaderInput.containerTypeCode',
  },
  {
    text: title(i18n.t('failedOrders.tableHeaders.shippingPostcode')),
    sortable: false,
    value: 'billingHeaderInput.shippingPostcode',
  },
  {
    text: title(i18n.t('failedOrders.tableHeaders.district')),
    sortable: false,
    value: 'billingHeaderInput.district',
  },
  {
    text: title(i18n.t('failedOrders.tableHeaders.createdAt')),
    sortable: false,
    value: 'createdAt',
  },
  {
    text: title(i18n.t('failedOrders.tableHeaders.updatedAt')),
    sortable: false,
    value: 'updatedAt',
  },
]

export default {
  name: 'FailedOrders',
  components: {
    'failed-orders-upload-modal': FailedOrdersUploadModal,
    'reprocess-orders-modal': ReprocessOrdersModal,
    'export-button': ExportButton,
  },
  mixins: [ClientMixin],
  data() {
    return {
      toggleBanner: false,
      modal: {
        component: '',
        show: false,
      },
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      // filters
      activeFilters: 0,
      exceptionTypeFilter: '',
      hubooBoxFilter: '',
      statusFilter: '',
    }
  },

  computed: {
    ...mapState('failedOrders', {
      exceptions(state) {
        return state.failedOrders
      },
    }),

    availableExceptionType() {
      const exceptionTypes = this.exceptions.meta.filters.exceptionType
      return [
        {
          text: i18n.t('failedOrders.filters.selectAll'),
          value: '',
        },
        {
          text: i18n.t('failedOrders.filters.shippingCodeEmpty'),
          value: exceptionTypes.shippingCodeIsEmpty,
        },
        {
          text: i18n.t('failedOrders.filters.fulfilmentPriceEmpty'),
          value: exceptionTypes.fulfilmentPriceLineIsEmpty,
        },
        {
          text: i18n.t('failedOrders.filters.clientConsignmentNS'),
          value: exceptionTypes.miClientConsignmentNotSupported,
        },
      ]
    },

    availableStatus() {
      const statusTypes = this.exceptions.meta.filters.status

      return [
        {
          text: i18n.t('failedOrders.filters.toProcess'),
          value: statusTypes.toProcess,
        },
        {
          text: i18n.t('failedOrders.filters.open'),
          value: statusTypes.open,
        },
        {
          text: i18n.t('failedOrders.filters.reopened'),
          value: statusTypes.reopened,
        },
        {
          text: i18n.t('failedOrders.filters.resolved'),
          value: statusTypes.resolved,
        },
      ]
    },

    reprocessData() {
      return this.$store.getters['failedOrders/getReprocessData']
    },

    bannerMessage() {
      return i18n.t('failedOrders.successMessage', {
        skipped: this.reprocessData.data.skipped,
        reprocessed: this.reprocessData.data.reprocessed,
      })
    },

    exportUrl() {
      return this.exceptions?.exportUrl
    },

    fallbackNumber() {
      return this.meta?.openWithFallback || 0
    },

    headers() {
      return tableHeaders
    },

    items() {
      return this.exceptions.data.map(item => {
        return {
          ...item,
          createdAt: this.formatDate(item.createdAt),
          updatedAt: this.formatDate(item.updatedAt),
        }
      })
    },

    isButtonDisabled() {
      return this.loading || this.items.length === 0
    },

    isReprocessLoading() {
      return this.$store.getters['core/apiEndpointIsLoading']({
        method: 'POST',
        url: '/reprocess-failed-orders',
      })
    },

    loadingStates() {
      return this.loading || this.isReprocessLoading
    },
    meta() {
      return this.exceptions.meta || { total: this.items.length }
    },

    url() {
      return '/failed-orders'
    },
  },

  methods: {
    ...mapActions({
      fetch: 'failedOrders/fetchFailedOrders',
      reprocess: 'failedOrders/reprocessFailedOrders',
    }),
    fetchFailedOrders() {
      if (this.loading) return

      const params = {}
      if (this.options.page) params.page = this.options.page || 1
      if (this.options.itemsPerPage) params.itemsPerPage = this.options.itemsPerPage
      if (this.dateRange) params.manifest_updated_at = this.dateRange?.join(',')
      if (this.hubooBoxFilter) params.huboo_box = this.hubooBoxFilter
      if (this.exceptionTypeFilter) params.exception_type = this.exceptionTypeFilter
      if (this.statusFilter) params.status = this.statusFilter

      this.fetch({
        params,
      })
    },
    onImportCsvClicked() {
      this.modal = { component: 'failed-orders-upload-modal', show: true }
    },
    onFiltersClear() {
      this.exceptionTypeFilter = ''
      this.hubooBoxFilter = ''
      this.statusFilter = ''
      this.activeFilters = 0

      this.options.page === 1 ? this.fetchFailedOrders() : (this.options.page = 1)
    },
    onApplyFilters() {
      this.options.page = 1

      const filters = [this.exceptionTypeFilter, this.hubooBoxFilter, this.statusFilter]
      this.activeFilters = filters.filter(item => item !== '').length

      this.fetchFailedOrders()
    },

    onReprocessOpenModal() {
      this.modal = { component: 'reprocess-orders-modal', show: true }
    },

    async reprocessOrders(payload) {
      let isSuccess = false
      const { fallback } = payload
      isSuccess = await this.reprocess({
        params: {
          fallback,
        },
      })
      if (isSuccess) {
        this.modal.show = false
        this.toggleBanner = isSuccess
      }
    },
  },

  watch: {
    options: {
      handler() {
        this.fetchFailedOrders()
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.banner {
  margin-bottom: 1.25rem;
}
.filter-row {
  display: flex;
  flex-flow: row wrap;
}
.break {
  flex-basis: 100%;
  height: 0;
}
</style>
