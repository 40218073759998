<template>
  <huboo-modal :value="value" @input="$emit('input', $event)" max-width="800px">
    <v-card v-if="value">
      <huboo-form
        @submit="onSubmit"
        :fields="fields"
        :loading="loading"
        :title="$t('failedOrders.failedOrdersCsvUpload') | title"
        id="uploadFailedOrders"
        lazyValidation
        showSubmit
      />
      <div class="upload-info-message">
        {{ $t('failedOrders.csvUploadInformationMessage') }}
      </div>
    </v-card>
  </huboo-modal>
</template>

<script>
import { isRequired } from '@/utilities/validations'
import { CSV_PRICING_UPLOAD_TYPES } from '@/utilities/constants'

const { FULFILMENT_DEFAULT } = CSV_PRICING_UPLOAD_TYPES

export default {
  name: 'FailedOrdersUploadModal',
  components: {},
  props: {
    value: Boolean,
  },
  data: () => ({}),
  computed: {
    fields() {
      return [
        {
          errorMessages: this.validationError('file'),
          label: this.$t('common.file'),
          name: 'file',
          rules: [isRequired],
          component: 'huboo-uploader',
          validateOnBlur: false,
          blurOnEnter: false,
          accept: ['.csv'],
        },
      ]
    },
    hasErrors() {
      return this.$store.getters['core/hasErrors']
    },
    loading() {
      return this.$store.getters['core/apiEndpointIsLoading']({
        method: 'POST',
        url: this.url,
      })
    },
    url() {
      return `/fulfilment-default/import`
    },
  },
  methods: {
    async onSubmit(e) {
      await this.$store.dispatch('billing/storePricingUpload', {
        url: this.url,
        file: e?.values.file,
        type: FULFILMENT_DEFAULT,
      })

      if (!this.hasErrors) {
        this.$emit('input', false)
      }
    },
    validationError(e) {
      return this.$store.getters['core/validationError'](e)
    },
  },
}
</script>

<style lang="scss" scoped>
.upload-info-message {
  padding: 0 1.3rem 1.3rem;
  color: grey;
  font-style: italic;
}
</style>
